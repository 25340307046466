import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../../components/Layout'
import Link from '../../components/Link'
import styles from './work.module.scss'

const title = 'Catapult meeting their revenue goals'
const description =
  'Catapult partnered with us to leverage our expertise in improving ecommerce experiences to turn more visitors into buyers.'
const image = 'https://www.zero.london/case-catapult@2x.png'
const imageAlt = 'Catapult desktop and mobile websites'
const imageDimensions = [1508, 988]

export default ({ location }) => (
  <Layout location={location} back>
    <Helmet>
      <title>Catapult meeting their revenue goals</title>
      <meta name='twitter:card' content='summary_large_image' />
      <meta name='twitter:title' content={title} />
      <meta name='twitter:description' content={description} />
      <meta name='twitter:image' content={image} />
      <meta name='twitter:image:alt' content={imageAlt} />
      <meta property='og:title' content={title} />
      <meta property='og:description' content={description} />
      <meta property='og:image' content={image} />
      <meta property='og:image:alt' content={imageAlt} />
      <meta property='og:image:width' content={imageDimensions[0]} />
      <meta property='og:image:height' content={imageDimensions[1]} />
    </Helmet>
    <div className={styles.Case}>
      <div className={styles.Intro}>
        <div className={styles.Screenshots}>
          <div className={styles.ImageCatapult}>
            <img
              alt='Catapult website screenshot'
              srcSet={`
                /case-catapult.png 1x,
                /case-catapult@2x.png 2x,
                /case-catapult@3x.png 3x
              `.replace(/\n/g, ' ')}
            />
          </div>
        </div>
        <aside>Catapult</aside>
        <h1>Catapult meeting their revenue goals</h1>
      </div>

      <div className={styles.Content}>
        <div className={styles.Description}>
          <h2>Challenge</h2>
          <p>
            Catapult partnered with us to leverage our expertise in improving ecommerce experiences to turn more
            visitors into buyers.
          </p>
          <p>
            We helped them improve their mobile and web experiences for playrsmartcoach.com. The goal with this cycle of
            work was to facilitate their revenue goals for the festive season.
          </p>
          <p>
            This included implementing tactical changes to streamline the user experience, improve site speed, and
            re-define the purchase funnel to facilitate conversion.
          </p>

          <h2>Results</h2>
          <p>
            We helped Catapult improve site speed by ~40%, reduce Bounce Rate by ~5%, and generate higher revenue over
            the months following the launch.
          </p>
        </div>

        <div className={styles.Specs}>
          <h3>Deliverables</h3>
          <p className={styles.small}>
            Analysis and evaluation, Strategy, Conversion Rate Optimisation, UI development
          </p>

          <h3>Tech Stack</h3>
          <p className={styles.small}>JavaScript, Shopify, AWS</p>

          <h3>Duration</h3>
          <p className={styles.small}>4 weeks</p>
        </div>

        <div className={styles.Quote}>
          <div className={styles.Photo}>
            <img className={styles.ImageOne} alt='Benoît Simeray' src='/benoit-simeray.jpg' />
            <aside className={styles.mobile}>Benoît Simeray – CEO Playr (Catapult Sports)</aside>
          </div>
          <div>
            <p>
              “A technology partner you can trust. They jumped in at a critical time and delivered quality at speed.”
            </p>
            <aside className={styles.desktop}>Benoît Simeray – CEO Playr (Catapult Sports)</aside>
          </div>
        </div>

        <div className={styles.Next}>
          <h2>Next</h2>
          <Link to='/work/ucsd-cmu-sleep' monochrome>
            <strong>CSD and CMU</strong> – Reward students for their sleep
          </Link>
        </div>
      </div>
    </div>
    <div className={styles.Contact}>
      <p>
        Need an opinion of your project?{' '}
        <Link button small to='/contact'>
          Let’s talk
        </Link>
      </p>
    </div>
    <div className={styles.FinalSection}>
      <h4>Are you an ambitious entrepreneur or business leader?</h4>
      <Link large button to='/contact'>
        Get our view on your project
      </Link>
    </div>
  </Layout>
)
